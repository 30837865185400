/**
 * To start using events, just import this module.
 * You can check out the mitt docs on how to interact with the exported instance.
 * https://github.com/developit/mitt
 */
import mitt, { Emitter } from 'mitt';
import websocketClient from './websocketClient';
import websocketBindings from './websocketBindings';

const emitter: Emitter<Events> = mitt<Events>();

Object.keys(websocketBindings).forEach(channel => {
    Object.keys(websocketBindings[channel]).forEach(event => {
        emitter.on(event, data => websocketClient.emit(
            websocketBindings[channel][event].name,
            websocketBindings[channel][event].transform(data),
            channel,
        ));
    });
});

export default emitter;
