<script setup lang="ts">
import { ref } from "vue";
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from "@headlessui/vue";
import SimpleButton from "../SimpleButton.vue";
import HeroIcon from "../HeroIcon.vue";

const emit = defineEmits(['close', 'confirm'])
const props = defineProps({
    title: String,
    description: String,
    working: Boolean,
    showCloseButton: Boolean,
    showCancelButton: Boolean,
    showConfirmButton: Boolean,
    size: {
        type: String,
        default: 'max-w-md',
    },
    closeOnClick: {
        type: Boolean,
        default: true,
    },
    confirmationLabel: String
})

const isOpen = ref(true);

function closeModal() {
    isOpen.value = false;
    emit('close');
}
function openModal() {
    isOpen.value = true;
}

function confirm() {
    isOpen.value = false;
    emit('confirm');
}

defineExpose({
    closeModal,
    openModal,
})

</script>

<template>
    <TransitionRoot appear :show="closeOnClick ? isOpen : true" as="template">
        <Dialog as="div" @close="closeModal" class="relative z-10">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 text-center">
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            :class="size"
                            data-cy="dialog-panel"
                            class="w-full transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all"
                        >
                            <div class="p-6">
                                <div v-if="showCloseButton"
                                    @click="closeModal"
                                    class="hidden sm:block absolute top-0 right-0 pt-4 pr-4"
                                >
                                    <button type="button"
                                            class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        <span class="sr-only">Close</span>
                                        <HeroIcon icon="XMark" class="w-6 h-6"></HeroIcon>
                                    </button>
                                </div>

                                <DialogTitle as="h3" class="text-2xl font-medium text-gray-900">
                                    {{ title }}
                                </DialogTitle>
                                <div class="mt-2">
                                    <slot></slot>

                                    <template v-if="!$slots.default">
                                        <p class="text-gray-500">
                                            {{ description }}
                                        </p>
                                    </template>
                                </div>

                                <div class="flex justify-end mt-6 space-x-2">
                                    <SimpleButton
                                        v-if="showConfirmButton"
                                        @click="confirm"
                                        :disabled="working"
                                        type="primary"
                                        data-cy="dialog-confirm"
                                    >
                                        {{ confirmationLabel || $t('confirm') }}
                                    </SimpleButton>
                                    <SimpleButton
                                        v-if="showCancelButton"
                                        @click="closeModal"
                                        :disabled="working"
                                        type="outline"
                                    >
                                        {{ $t('cancel') }}
                                    </SimpleButton>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
