export enum DrawingType {
    FURNITURE = 'furniture',
    WINDOW = 'window',
    DOOR = 'door',
    WALL = 'wall',
    FLOOR = 'floor',
    LIGHTING = 'lighting',
}

//We define Carpentry types to have set logic, like not using raycasting or collision
const carpentryTypes: string[] = [
    DrawingType.WINDOW, 
    DrawingType.DOOR,
];

export {
    carpentryTypes
}