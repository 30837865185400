<script lang="ts">
import DefaultTransition from './layout/DefaultTransition.vue';

export default {
    components: { DefaultTransition }
}
</script>

<template>
    <DefaultTransition>
        <div class="absolute inset-0">
            <div
                v-for="i in 3"
                :key="`pulsating-circle${i}`"
                class="absolute inset-0 flex items-center justify-center pointer-events-none"
            >
                <div
                    :style="`animation-delay: ${(i - 1)}s`"
                    class="rounded-full w-256 h-256 border border-white animate-pulsate opacity-0"
                ><div class="pt-full"></div></div>
            </div>
        </div>
    </DefaultTransition>
</template>
