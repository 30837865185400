import type { Coordinates } from "@/types/coordinates.types";
import type{ PositionData } from "@/types/configurationmarkers.types";
import { Wall } from "./classes/Wall";

/**
 * Calculation to determine the offset of an object based on the wall it's placed on and the angle.
 * 
 * @param {number} width - The width of the object.
 * @param {number} angle - The angle at which the object is placed on the wall (in degrees).
 *  
 * @returns {Object} - An object containing the calculated x and y offsets.
 */
// export function calculateOffset(width: number, angle: number): Coordinates {
//     let offsetX = width * Math.cos(angle * Math.PI / 180);
//     let offsetY = width * Math.sin(angle * Math.PI / 180);
    
//     return { x: offsetX, y: offsetY };
// }


/**
 * Calculates the position of a marker on a wall.
 * 
 * @param {Wall} wall - The wall on which the marker is placed.
 * @param {coordinateX} number - The coordinates of the marker.
 * 
 * @returns {Object} - The calculated marker position.
 */
// export function calculateMarkerPosition(wall: Wall, coordinateX: number): { x: number, y: number, angle: number} {
//     const angle = wall.getAngle();
//     return pointOnLineAtDistance(wall.from, angle, -coordinateX); // point on wall
// }

/**
 * Calculates a point on a line at a certain distance from a given coordinate.
 */
function pointOnLineAtDistance(coord: Coordinates, bearing: number, distance: number): { x: number, y: number, angle: number} {
    const angle = bearing * Math.PI / 180;
    const x1 = coord.x;
    const y1 = coord.y;
    const x2 = x1 - Math.cos(angle) * distance;
    const y2 = y1 - Math.sin(angle) * distance;

    return { x: x2, y: y2, angle: bearing };
}

/**
 * Calculates the position of an object on a wall.
 * 
 * @param {Wall} wall - The wall on which the object is placed.
 * @param {Coordinates} coordinates - The coordinates of the object.
 * @returns {Object} - The calculated object position.
 */
export function getPositionOnWall(wall: Wall, coordinates: Coordinates, flipDirection = false): Coordinates {
    const angle = wall ? wall.getAngleRadians() : 0;
    const start = flipDirection ? wall.to : wall.from; // Start point of the wall

    const dx = coordinates.x - start.x;
    const dy = coordinates.y - start.y;

    // Calculate the position on the wall
    const positionOnWall = dx * Math.cos(angle) + dy * Math.sin(angle);

    return {
        x: Math.abs(positionOnWall),
        y: 0,
    };
}

/**
 * Detect what side the object is from the wall givin the coordinates
 */
export function wallSide(wall: Wall, objectPos: Coordinates): "back" | "front" {
    const wallDirection = wall.getWallDirection();
    const vector = {
        x: objectPos.x - wall.from.x,
        y: objectPos.y - wall.from.y
    };
    // Calculate cross product
    const crossProduct = wallDirection.x * vector.y - wallDirection.y * vector.x;
    return crossProduct < 0 ? "back" : "front";
}

/**
 * Get the correct position for a newly created marker.
 * When no position is giving just add it to the center of the room
 */
export function getNewConfigurationMarkerPosition(
    position: Coordinates, 
    containerPosition: Coordinates, 
    centerX: number, 
    centerY: number
    ): PositionData {
    
    if (position !== undefined) {
        //When you get a position (drag, drop) calculate the pos inside the room
        return {
            x: position.x - containerPosition.x,
            y: position.y - containerPosition.y
        };
    }
    //Default position is the 'center' of the room
    return { x: centerX, y: centerY };
}

/**
 * When a new configurationMarker is created and it is wall_mounted
 * Get the correct position in the center of the closest wall
 */
export function calculateWallPosition(closestWall: Wall): PositionData {
    const centerWall = closestWall.getCenter();
    const side = wallSide(closestWall, centerWall);

    const rotation = closestWall.getAngle();


    return { x: centerWall.x, y: centerWall.y, wall: closestWall.id, side, rotation };
}

/**
 * Snap an angle to the nearest specified angle with a given threshold.
 * 
 * @param angleRadians The angle in radians to snap.
 * @param nearestAngleDegrees The desired nearest angle in degrees. Default is 90 degrees.
 * @param thresholdDegrees The threshold in degrees for snapping. Default is 5 degrees.
 * @return The snapped angle in radians.
 */
export function snapToAngle(
    angleRadians: number, 
    nearestAngleDegrees: number = 90, 
    thresholdDegrees: number = 5
    ): number {
    // Convert degrees to radians for calculation
    const nearestAngleRadians = (Math.PI / 180) * nearestAngleDegrees;
    const thresholdRadians = (Math.PI / 180) * thresholdDegrees;

    // Calculate the nearest angle in radians
    const nearestAngle = Math.round(angleRadians / nearestAngleRadians) * nearestAngleRadians;

    // Calculate the distance to the nearest angle
    const distanceToNearest = Math.abs(nearestAngle - angleRadians);

    // Return the snapped angle if within the threshold, otherwise return the original angle
    return distanceToNearest < thresholdRadians ? nearestAngle : angleRadians;
}