import { createI18n } from 'vue-i18n';
import { router } from '@inertiajs/vue3';
import axios from 'axios';

const messageImports = import.meta.glob('./*.json', { query: '?raw' });

const messages = {};

for (const path in messageImports) {
    messageImports[path]().then((langfile) => {
        // Extract the language code from the path
        const langCode = path.match('./(.*).json')[1];
        // Parse the JSON file and store it in the messages object
        messages[langCode] =  JSON.parse(langfile.default);
    }).catch(error => {
        console.error('Error importing module:', error);
    });
}

let locale =  document.documentElement.lang || navigator.language.substring(0, 2) || 'en';

const instance = createI18n({
    legacy: false,
    locale: locale,
    messages
});

axios.defaults.headers.common['Accept-Language'] = instance.global.locale.value;

// Make sure an Accept-Language header is set with the i18n locale for all Inertia
// requests made to our backend.
router.on('before', (e) => {
    e.detail.visit.headers['Accept-Language'] = instance.global.locale.value;
})

export default instance;

export const i18n = instance.global;

export const updateLocale = async (locale) => {
    await axios.post(route('client.language'), { language: locale });

    instance.global.locale.value = locale;
    axios.defaults.headers.common['Accept-Language'] = locale;

    router.reload();
}
