import { useMarkerStore, useRoomStore } from "@/stores";
import { Configuration } from "@/types/configuration.types";
import { Wall } from "@/utils/classes/Wall";
import * as Room from "@/utils/pixiFloorplan/Room";

export default async function createRetailData(configuration: Configuration) {
    const configurationMarkers = [];
    const markerStore = useMarkerStore();
    const roomStore = useRoomStore();

    const walls = roomStore.room.floorplan_data.walls as Wall[];
    const roomCenter = Room.getRoomCenter(walls);

    // Create a configuration marker for the light and add it to the middle of the room.
    configurationMarkers.push(await markerStore.createConfigurationMarker(
        "lighting",
        roomCenter,
        configuration.id
    ));

    // Create a configuration marker for the floor cladding
    configurationMarkers.push(await markerStore.createConfigurationMarker(
        "floor",
        undefined,
        configuration.id
    ));

    // For each wall, create a configuration marker for the wall cladding
    for (const wall of walls) {
        configurationMarkers.push(await markerStore.createConfigurationMarker(
            "wall_cladding",
            { wall: wall.id },
            configuration.id
        ));
    }

    //Add a door to the first wall
    const firstWall = roomStore.getWall(1);
    configurationMarkers.push(await markerStore.createConfigurationMarker(
        "door",
        {
            wall: 1,
            x: firstWall.getCenter().x,
            y: firstWall.getCenter().y,
            rotation: firstWall.getAngle(),
        },
        configuration.id
    ));

    //Add a window to the third wall
    const thirdWall = roomStore.getWall(3);
    configurationMarkers.push(await markerStore.createConfigurationMarker(
        "window",
        {
            wall: 3,
            x: thirdWall.getCenter().x,
            y: thirdWall.getCenter().y,
            rotation: thirdWall.getAngle(),
        },
        configuration.id
    ));

    return configurationMarkers;
}
