import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';
import hmacSHA256 from 'crypto-js/hmac-sha256';

class WebSocketClient {

    protected client: Pusher;
    protected channels: Array<PusherTypes.Channel> = [];

    public constructor(channels: Array<string> = []) {

        if (!__EMIT_SOCKET_EVENTS__) {
            return;
        }

        this.client = new Pusher('oiw3vzth57', {
            cluster: 'mt1',
            wsHost: '127.0.0.1',
            wssPort: 6001,
            forceTLS: true,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
            authorizer: (channel, options) => {
                return {
                    authorize: function (socketId, callback) {
                        callback(null, {auth: 'oiw3vzth57:' + hmacSHA256(
                            `${socketId}:${channel.name}`,
                            'tkxp4ejiqt'
                        )});
                    }
                };
            }
        });

        ['private-global', ...channels].forEach(channel => {
            this.channels.push(this.client.subscribe(channel));
        });
    }

    /**
     * Add an event listener for an event.
     */
    public listen(
        event: string,
        handler: (data: any) => void,
        channel: string = 'private-global'
    ) {
        if (!this.client) {
            return;
        }

        this.channel(channel).bind(event, handler);
    }

    /**
     * Trigger a client event.
     */
    public emit(
        event: string,
        data: Object,
        channel: string = 'global'
    ) {
        if (!this.client) {
            return;
        }

        this.channel(this.prefix(channel, 'private')).trigger(
            this.prefix(event, 'client'),
            data
        );
    }

    protected channel(name: string): PusherTypes.Channel {
        const channel = this.channels.find(channel => channel.name === name);

        if (!channel) {
            throw `Invalid websocket channel "${name}" specified!`;
        }

        return channel;
    }

    protected prefix(subject: string, prefix: string): string {
        return (subject.startsWith(`${prefix}-`) ? '' : `${prefix}-`) + subject;
    }
}

export default new WebSocketClient()
