import { defineStore } from "pinia";
import { useGeneralStore } from "./general";
import { FilterObject, SavedFilters } from "@/types/filtering.types";

interface State {
    filters: FilterObject[];
    savedFilters: SavedFilters;
}

export const useFilteringStore = defineStore('filtering', {
    state: (): State => {
        return {
            filters: [] as FilterObject[],
            savedFilters: [] as SavedFilters,
        }
    },
    getters: {
        availableFilters: (state): FilterObject[] => {

            const generalStore = useGeneralStore();

            return state.filters
                .filter((facetObject) => {
                    // Make sure we only have one price filter for the currently applied VAT setting.
                    return facetObject.type != 'price' || facetObject.metadata?.vat_percentage == generalStore.vatAmount;
                });
        }
    },
    actions: {
        resetFilters (productGroup: number) {
            this.updateSavedFilters(productGroup, []);
        },
        updateFacetData (filterIndex: number, facetData: object) {

            let filter = this.filters[filterIndex];

            if (filter.options.hasOwnProperty('min') && filter.options.hasOwnProperty('max')) {
                return;
            }

            // Update existing options in the filter.
            Object.keys(filter.options).forEach((key) => {
                let filterOption = filter.options[key];

                if (Number.isInteger(filterOption)) {
                    filter.options[key] = facetData.options[key] || 0;
                    return;
                }

                filterOption.count = Object.values(facetData.options).find(
                    (facetOption) => facetOption.id == filterOption.id
                )?.count || 0;
            });
        },
        setFacetData (productGroup: number, facetData: object[]) {
            facetData.forEach((facet) => {

                let index = this.filters.findIndex(
                    (filter) => (filter.productGroupId == productGroup) && (filter.facet == facet.facet)
                );

                if (index >= 0) {
                    return this.updateFacetData(index, facet);
                }

                this.filters.push({
                    ...facet,
                    productGroupId: productGroup,
                });
            });
        },
        updateSavedFilters (productGroup: number, savedFilterObjects: SavedFilters) {
            this.savedFilters = this.savedFilters.filter(
                (savedFilter) => savedFilter.productGroupId != productGroup
            ).concat(savedFilterObjects);
        },
        getFiltersForProductGroup (productGroup: number): [] {
            return this.savedFilters.filter(
                (savedFilter) => savedFilter.productGroupId == productGroup
            );
        },
        getAvailableFiltersForProductGroup (productGroup: number): [] {
            return this.availableFilters.filter((filter) => filter.productGroupId == productGroup);
        },
        getFilterQueriesForProductGroup (productGroup: number) {
            return this.getFiltersForProductGroup(productGroup).map(
                (savedFilter) => {
                    let filter = this.availableFilters.find((filter) => filter.productGroupId == savedFilter.productGroupId && filter.facet == savedFilter.facet);

                    if (!filter) {
                        return null;
                    }

                    const query = this.buildFilterQuery(filter, savedFilter.selectedValue);

                    if (!query) {
                        return null;
                    }

                    return (Array.isArray(query) ? query : [query]).map((q) => {
                        return {
                            type: filter.type,
                            query: q,
                        }
                    });
                }
            ).filter(Boolean);
        },
        buildFilterQuery (filter, value) {
            let query;

            switch (filter.type) {
                case 'brand':
                    query = {
                        'brand': value,
                    };
                    break;
                case 'price':
                    query = {
                        'min': value[0]/100,
                        'max': value[1]/100,
                        'vat_percentage': filter.metadata.vat_percentage,
                    };
                    break;
                case 'size':
                    query = {
                        [filter.facet]: {
                            'min': value[0],
                            'max': value[1],
                        }
                    };
                    break;
                case 'property':
                    query = this.buildPropertyQuery(filter, value);
                    break;
                case 'category':
                    query = {
                        'category': value,
                    };
                    break;
            };

            return query;
        },
        buildPropertyQuery (filter, value) {
            let query;

            switch (filter.metadata.property_type) {
                case 'boolean':
                    query = value.length == 1 ? {
                        'property_id': filter.metadata.property_id,
                        'comparison_operator': '=',
                        'numerical_value': value[0],
                    } : null;
                    break;
                case 'integer':
                    query = [
                        {
                            'property_id': filter.metadata.property_id,
                            'comparison_operator': '>=',
                            'numerical_value': value[0],
                        },
                        {
                            'property_id': filter.metadata.property_id,
                            'comparison_operator': '<=',
                            'numerical_value': value[1],
                        },
                    ];
                    break;
                case 'property_value':
                    query = {
                        'property_id': filter.metadata.property_id,
                        'comparison_operator': 'IN',
                        'property_value_id': value,
                    };
                    break;
            };

            return query;
        }
    },
});
