<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { X, CircleX, CircleAlert, CircleCheck, Info } from 'lucide-vue-next';

// Props for our component,
// these are the same as Notitfication interface.
const props = defineProps({
    id: { type: String, required: true },
    type: {
        type: String,
        default: "info",
        required: false,
    },
    title: { type: String, default: null, required: false },
    message: {
        type: String,
        default: "Ooops! A message was not provided.",
        required: false,
    },
    autoClose: { type: Boolean, default: true, required: false },
    duration: { type: Number, default: 5, required: false },
});

// Defining emits
// for closing a notification
const emit = defineEmits<{
    (e: "close"): void;
}>();

// some reactive values to manage the notification
const timer = ref(-1);
const startedAt = ref<number>(0);
const delay = ref<number>(0);

// setting up the automatic
// dismissing of notificaton
// after the specified duration
onMounted(() => {
    if (props.autoClose) {
        startedAt.value = Date.now();
        delay.value = props.duration * 1000;
        timer.value = setTimeout(close, delay.value);
    }
});

// a computed property to set
// the icon for the notification
const toastIcon = computed(() => {
    switch (props.type) {
        case "error":
            return CircleX;
        case "warning":
            return CircleAlert;
        case "success":
            return CircleCheck;
        default:
            return Info;
    }
});

// a computed property to set
// the icon and progres bar color
// for the notification
const toastStyles = computed(() => {
    switch (props.type) {
        case "error":
            return {
                bg: "bg-red-600",
                text: "text-red-600"
            };
        case "warning":
            return {
                bg: "bg-orange-400",
                text: "text-orange-400"
            };
        case "success":
            return {
                bg: "bg-green-500",
                text: "text-green-500"
            };
        default:
            return {
                bg: "bg-blue-500",
                text: "text-blue-500"
            };
    }
});

// a computed property to set
// the title of the notification
const toastTitle = computed(() => {
    return props.title && props.title !== null ? props.title : "Notification";
});

// a method to close the
// notification and emit the action
const close = () => {
    emit("close");
};
</script>

<template>
    <div
        class="bg-white rounded-xl shadow-md cursor-pointer overflow-hidden relative max-w-lg min-w-[20rem] min-h-[4rem] px-6 py-5 transition-all ease-in-out duration-75"
        @click.prevent="close"
        :ref="id"
        data-cy="toast-notification"
    >
        <div @click="close" class="absolute top-4 right-4 flex justify-center h-8 w-8 cursor-pointer" title="Close">
            <X class="w-6 h-6"/>
        </div>

        <div class="body flex gap-6 place-items-center">
            <div class="flex-shrink-0 self-center">
                <component :is="toastIcon" class="w-8 h-8 border-right" :class="toastStyles.text"></component>
            </div>

            <div class="bg-gray-300 w-0.5 h-14"></div>
            <div class="content flex flex-col gap-4">
                <div class="font-semibold">{{ toastTitle }}</div>

                <p class="content__message break-words">{{ message }}</p>
            </div>
        </div>
        <div v-if="autoClose"
            class="animate-progress absolute bottom-0 left-0 h-2 w-full"
            :class="toastStyles.bg"
            :style="`animation-duration: ${duration}s`"
        ></div>
    </div>
</template>
