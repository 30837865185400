import { defineStore } from "pinia";
import { useGeneralStore } from "./general";
import type { RoomState } from "@/types/room.types";
import type { Coordinates } from "@/types/coordinates.types";
import { Wall } from "@/utils/classes/Wall";

export const useRoomStore = defineStore('rooms', {
    state: (): RoomState => ({
        rawItems: [],
        room: null,
        wallThickness: 18,
        wallColor: 0xDADEE5,
        windowColor:  0xFAFAFA, //gray
        clientColor: 0x84cff1, //blue
        clientPackageId: null,
    }),
    getters: {
        items: (state) => {
            return state.rawItems;
        },
        roomCount: (state) => {
            return state.rawItems.length;
        },
        square_meters: (state) => {
            if(state.room){
                return state.room.square_meters;
            }
            return 0;
        },
        square_meters_with_cutoff(){
            const generalStore = useGeneralStore();
            const cutoff = generalStore.cutoffPercentage;

            if(cutoff > 0){
                return this.square_meters * cutoff;
            }

            return this.square_meters;
        },
        itemsExcludingActiveRoom (state) {
            if(!state.room || !this.roomCount || this.roomCount < 2){
                return;
            }

            return this.items.filter(item => item.id !== state.room.id);
        },
        highestWallId(): number {
            const walls = this.room?.floorplan_data.walls || [];
            return walls.reduce((maxId, wall) => (wall.id > maxId ? wall.id : maxId), 0);
        },
    },
    actions: {
        setAll(data) {
            this.rawItems = JSON.parse(JSON.stringify(data));
        },
        setRoomData(data): void {
            this.room = JSON.parse(JSON.stringify(data));

            this.room.floorplan_data.walls = this.room.floorplan_data.walls.map(wallData => new Wall(wallData));
        },
        addNewWall(from: Coordinates, to: Coordinates, free: boolean, height: number): void{
            const newWall = new Wall({
                id: this.highestWallId + 1,
                from: { x: from.x, y: from.y },
                to: { x: to.x, y: to.y },
                free: free,
                height: height,
            });
            this.room.floorplan_data.walls.push(newWall);
        },
        getWall(wallId: number): Wall{
            const wallIndex = this.room.floorplan_data.walls.findIndex(wall => wall.id === wallId);
            if (wallIndex >= 0) {
                return this.room.floorplan_data.walls[wallIndex];
            }
            return undefined;
        },
        getWallSquareMetersWithCutoff(wallId: number): number{
            const wall = this.getWall(wallId);
            if (wall !== undefined) {
                const generalStore = useGeneralStore();
                const cutoff = generalStore.cutoffPercentage;

                let wallLength = wall.getLength() / 10000;
		        wallLength = parseFloat((wallLength).toFixed(2));
                const area = wallLength * wall.height;

                if(cutoff > 0){
                    return area * cutoff;
                }

                return area;
            }
        },
        removeWall(wallId: number): void {
            this.room.floorplan_data.walls = this.room.floorplan_data.walls.filter(wall => wall.id !== wallId);
        },
        updateWallPosition(id: number, from: Coordinates, to: Coordinates): void {
            const wall = this.getWall(id);

            if (wall !== undefined) {
                wall.from = { x: from.x, y: from.y };
                wall.to = { x: to.x, y: to.y };
            }
        },
    }
});
