import { defineStore } from "pinia";

export const useContextStore = defineStore('context', {
    state: () => {
        return {
            currentPage: null,
            previousPage: null,
        }
    },
    actions: {
        updateCurrentPage (page) {
            this.previousPage = this.currentPage;
            this.currentPage = page;
        },
        updateBrandColor (hexCode) {
            this.brandColor = hexCode;
        }
    }
});
