<script setup lang="ts">
import { ref } from 'vue';
import { i18n, updateLocale } from '@/lang/i18n';

const props = defineProps<{
    dark?: boolean;
}>();

const open = ref(false);

const setLocale = async (locale: string) => {
    if(!locale){
        return
    }

    if (locale == i18n.locale.value) {
        return;
    }

    updateLocale(locale);

    open.value = false;
}

defineExpose({ setLocale })
</script>

<template>
    <main>
        <div class="relative">
            <div class="h-14 w-14 flex justify-center items-center">
                <button
                    @click="open = !open"
                    class="block w-12 h-12 relative z-20"
                >
                    <img
                        :src="`/images/lang/${$i18n.locale}.svg`"
                        :alt="$i18n.locale"
                        class="rounded-full shadow-md w-full h-full"
                    >
                    <span class="absolute inset-0 rounded-full ring ring-white ring-inset"></span>
                </button>
            </div>

            <div class="absolute left-0 bottom-0 w-64">
                <ul>
                    <li
                        v-for="(locale, index) in $i18n.availableLocales"
                        :key="`locale-${locale}`"
                        class="absolute left-0 bottom-4 transform-gpu transition duration-300 ease-out"
                        :class="[ open ? 'opacity-1' : 'opacity-0' ]"
                        :style="[ open ? `transform: translateY(calc(-4rem - ${index * 3}rem));` : 'transform: translateY(0);' ]"
                    >
                        <button
                            @click="setLocale(locale)"
                            class="flex items-center ml-3"
                        >
                            <div class="relative mr-4">
                                <img
                                    class="w-7 h-7 rounded-full shadow-md"
                                    :src="`/images/lang/${locale}.svg`"
                                    :alt="locale"
                                >
                                <span class="absolute inset-0 rounded-full ring ring-white ring-inset"></span>
                            </div>
                            <span
                                class="leading-none text-xl transform-gpu delay-300 transition duration-300 ease-out"
                                :class="[ open ? 'translate-x-0 opacity-1' : 'translate-x-8 opacity-0', dark ? 'text-primary-gray-700' : 'text-white' ]"
                            >{{ $t(locale) }}</span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </main>
</template>
