<script setup lang="ts">
import { ref } from "vue";
import event from '../events/eventBus';
import PopupModal from './ui/headlessui/PopupModal.vue';
import { router, usePage } from '@inertiajs/vue3'
import { useMarkerStore, useRoomStore } from '@/stores';
import { updateLocale } from '@/lang/i18n';

type PropTypes = {
    idleDuration?: number;
    finalDuration?: number;
}

const props = withDefaults(defineProps<PropTypes>(), {
    idleDuration: 600, //seconds, Total duration before showing popup
    finalDuration: 120, //seconds, Before redirected to homepage
})

const markerStore = useMarkerStore();
const roomStore = useRoomStore();
const page = usePage().props;

const showPopup = ref(false);

const onidle = async () => {
    markerStore.$reset();
    roomStore.clientPackageId = null;
    event.emit('sessionStop', {});
    if (page.preferred_locale) {
        await updateLocale(page.preferred_locale);
    }
    router.visit(route('client.start'), { replace: false });
};
</script>

<template>
     <v-idle v-show="false"
            @idle="showPopup = true"
            :duration="idleDuration"
            :events="['keydown', 'mousedown', 'touchstart']"/>

    <PopupModal v-if="showPopup"
                @close="showPopup = false"
                show-close-button
                show-cancel-button
                :title="$t('inactivity_title')"
                data-cy="idle-popup"
    >
        <p class="flex flex-wrap text-gray-500">
            {{ $t('inactivity_message')}}
                <v-idle @idle="onidle" :duration="finalDuration" :events="[]" class="mx-2"/>
            {{ $t('seconds')}}.
        </p>
    </PopupModal>
</template>
