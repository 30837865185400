<script setup lang="ts">

import * as _ from 'lodash-es';
import DefaultTransition from './layout/DefaultTransition.vue';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useGeneralStore } from '@/stores';
import { Client } from '@/types/client.types';

const client: Client = useGeneralStore().client;

const defaultImages = [
  "/images/slider/compressed1.jpeg",
  "/images/slider/compressed2.jpeg",
  "/images/slider/compressed3.jpeg",
  "/images/slider/compressed4.jpeg",
];

const currentSlide = ref(1);
const interval = ref(null);

const clientImages = computed(() => {
  return client?.sliderImages ?? [];
});

const images = computed(() => {
  return defaultImages.concat(clientImages.value);
});

function resetSlider() {
  if (interval.value) {
    clearInterval(interval.value);
  }
  currentSlide.value = firstSlideIndex();
  interval.value = setInterval(() => {
    currentSlide.value = (currentSlide.value >= images.value.length)
      ? firstSlideIndex()
      : currentSlide.value + 1;
  }, 10000);
}

function firstSlideIndex() {
  return clientImages.value.length ? 5 : 1;
}

watch(images, (oldSet, newSet) => {
  if (!_.isEqual(oldSet, newSet)) {
    resetSlider();
  }
});

onMounted(() => {
  resetSlider();
});

onBeforeUnmount(() => {
  clearInterval(interval.value);
});
</script>

<template>
    <DefaultTransition>
        <div class="bg-primary-gray-300">
            <div
                v-for="(image, index) in images"
                :key="'slider-image-' + index"
                class="absolute h-full w-full transition-opacity duration-2000 bg-cover bg-center"
                :class="{ 'opacity-0': index != currentSlide - 1 }"
                :style="'background-image: url(' + image + ')'"
            ></div>
            <div class="h-full w-full bg-black opacity-30"></div>
        </div>
    </DefaultTransition>
</template>
