import { defineStore } from "pinia";

export const availableSortings = [
    'auto',
    'price-asc',
    'price-desc',
    'size-asc',
    'size-desc',
];

type Sorting = typeof availableSortings[];

interface SelectedSorting {
    productGroup: number;
    sorting: Sorting;
}

interface State {
    sortings: SelectedSorting[];
}

export const useSortingStore = defineStore('sorting', {
    state: (): State => {
        return {
            sortings: [] as SelectedSorting[],
        }
    },
    actions: {
        updateSorting (productGroup: number, sorting: Sorting) {

            let existingSorting = this.sortings.find(
                sorting => sorting.productGroup == productGroup
            );

            if (!existingSorting) {
                this.sortings.push({
                    productGroup,
                    sorting,
                });
            } else {
                existingSorting.productGroup = productGroup;
                existingSorting.sorting = sorting;
            }
        }
    },
    getters: {
        getSortingForProductGroup: (state) => {
            return (productGroupId) => state.sortings.find(
                sorting => sorting.productGroup == productGroupId
            )?.sorting || 'auto';
        }
    }
});
