import type { Coordinates } from "@/types/coordinates.types";
import {
    MarkerPoly,
    RaycastSet,
    RaycastSets,
    // RoomPoly,
    WallLine,
    WallPoly,
    collisionSystem,
    createRaycastSets,
    debugCollision,
} from '@/types/collision.types';
import { createObjectPolygon } from "@/utils/pixiFloorplan/Marker";
import { Line, SATVector, Response } from "detect-collisions";
import { RaycastHit, Body } from "detect-collisions/dist/model";
import { useRoomStore } from "@/stores";
import { Wall } from "../classes/Wall";

type SideToWall = {
    side: string;
    object: number;
    offset: number;
}

//For markers that are not wall mounted
//add collision to walls and update position
export function moveInsideRoom(markerBody: MarkerPoly, mousePosition: Coordinates): MarkerPoly{
    // //Update angle and position
    markerBody.setPosition(mousePosition.x, mousePosition.y);
    // // //UNTILL WE HAVE SWEEP IN THE LIBRARY
    collisionSystem.checkOne(markerBody, (response: Response) => {
        if (response.a.isMarker && response.b.isWall ||
            response.a.isMarker && response.b.isFreeWall) {
            markerBody.pos.x -= response.overlapV.x;
            markerBody.pos.y -= response.overlapV.y;
        }
    });
    collisionSystem.checkOne(markerBody, (response: Response) => {
        if (response.a.isMarker && response.b.isWall ||
            response.a.isMarker && response.b.isFreeWall) {
            markerBody.pos.x -= response.overlapV.x;
            markerBody.pos.y -= response.overlapV.y;
        }
    });
    collisionSystem.checkOne(markerBody, (response: Response) => {
        if (response.a.isMarker && response.b.isWall ||
            response.a.isMarker && response.b.isFreeWall) {
            markerBody.pos.x -= response.overlapV.x;
            markerBody.pos.y -= response.overlapV.y;
        }
    });
    markerBody.updateBody();
    debugCollision.update();

    return markerBody
}

export function handleFreeWall(
    closestWall: Wall,
    newPosition: Coordinates,
    sideToWall: SideToWall = { side: '', object: 0, offset: 0 }
    ): Coordinates {

    const innerVector = closestWall.getInnerVector();
    const closestPoint = closestWall.getClosestPointOnLine(newPosition);
    const dotResult = closestWall.getDotResult(newPosition);

    if (closestWall.closestDistance < 30) {
        sideToWall.offset = 8; // Set offset to half the wall thickness

        const offsetMultiplier = dotResult ? -1 : 1;

        return {
            x: (offsetMultiplier * innerVector.x * (sideToWall.object + sideToWall.offset)) + closestPoint.x,
            y: (offsetMultiplier * innerVector.y * (sideToWall.object + sideToWall.offset)) + closestPoint.y,
        };
    }

    return;
}

//Calculate the distance that the wall is from the closest wall
//As long the distance is big enough return true
export function calculateDistWallToObject(
    closestWall: Wall,
    mousePosition: Coordinates,
    distance: number,
    ): boolean {
    //Auto caluclate the angle object to wall
    //Calculate closest object angle
    // const normalizedDirection = Room.getNormalizedDirection(closestWall.value.from, closestWall.value.to);
    // const innerVector = Room.getInnerVector(normalizedDirection);

    // const objectRight = Collision.objectRotation('right', markerBody.angle)
    // const objectForward = Collision.objectRotation('forward', markerBody.angle)

    // const dotForward = (innerVector.x * objectForward.x) + (innerVector.y * objectForward.y);
    // const dotRight = (innerVector.x * objectRight.x) + (innerVector.y * objectRight.y);

    // if (Math.abs(dotRight) < Math.abs(dotForward)) {
    //     console.log("🚀 ~ LANGE KANT")
    // }else{
    //     sideToWall = {
    //         side: 'shortside',
    //         object: objectDimensions.width,
    //     }
            //angle += 90;
    //     console.log("🚀 ~ KORTE KANT")
    // }

    //Get the dotResult to know if it's inside the room or not
    const dotResult = closestWall.getDotResult(mousePosition);

    //Distance from the object to the wall
    const distWallToObject = closestWall.closestDistance;

    //As long as the distance is > 10 and object is inside the room you can freely drag
    if (distWallToObject > distance && !dotResult) {
        return true;
    }

    return
}

//Make sure the object stays inside the room
export function stayInsideRoom(
    objectPosition: Coordinates,
    closestWall: Wall,
    sideToWall: SideToWall = { side: '', object: 0, offset: 0 }
    ): Coordinates {

    const innerVector = closestWall.getInnerVector();
    const closestPoint = closestWall.getClosestPointOnLine(objectPosition);

    if(closestWall.free) {
        const dotResult = closestWall.getDotResult(objectPosition)
        const offsetMultiplier = dotResult ? -1 : 1;
        innerVector.x = offsetMultiplier * innerVector.x;
        innerVector.y = offsetMultiplier * innerVector.y;
        sideToWall.offset = 8;
    }

    const newPos: Coordinates = {
        x: (innerVector.x * (sideToWall.object + sideToWall.offset)) + closestPoint.x,
        y: (innerVector.y * (sideToWall.object + sideToWall.offset)) + closestPoint.y,
    };

    return newPos;
}

export function handleCollisionWithRaycast(response: Response, closestWall: Wall, markerBody: MarkerPoly): void {
    if(checkCorrectCollidingBodies(response, closestWall.id)){
        //When there is an overlap we draw raycasts to move the marker to the other side
        const firstRaycast = raycast(closestWall, markerBody);
        //With the inverted direction we then raycast again so we now to where we can move the marker to
        raycast(closestWall, markerBody, firstRaycast.directionInverted);
    }
}

/**
 * When we get a colliding response we should check whether it's with the correct bodies
 * We need to eliminate a check with the current wall the object is being dragged on
 */
function checkCorrectCollidingBodies(response: Response, closestWallId: number): boolean {
    return (
        response.a.isMarker &&
        response.b.isWall &&
        response.b.wall_id !== closestWallId
    );
}


function raycast(closestWall: Wall, markerPoly: MarkerPoly, directionInverted = null) {
    const normalizedDirection = closestWall.getDirectionVector();

    const raycastSets = createRaycastSets();

    markerPoly.points.forEach((point, i) => {
        const invertDirection = point.x < 0;

        if(directionInverted === invertDirection){
            return
        }

        if (!shouldPerformRaycast(point, directionInverted)){
            return
        }

        const { start, end } = calculateRaycastEndpoints(point, normalizedDirection, markerPoly);

        // const currentSet = point.x < 0 ? raycastSets.left : raycastSets.right;
        const currentSet = invertDirection ? raycastSets.left : raycastSets.right;
        currentSet.raycasts.push({start: start, end: end})

        const hit = performRaycast(start, end, closestWall);

        updateClosestHit(currentSet, start, hit);

        //Save the hit to draw later
        raycastSets.raycastHits.push(hit);

        //Create and save raycastLine to visualize, remove later
        const debugLine = collisionSystem.createLine(start, end, { isTrigger: false });
        raycastSets.raycastLines.push(debugLine);
    });

    //Draw the hits
    drawDebugHits(raycastSets.raycastHits);

    debugCollision.update()

    //Remove the raycast lines from the tree
    removeRaycastLinesFromTree(raycastSets.raycastLines);

    return determineNextPosition(markerPoly, raycastSets);
}

//checks whether a raycast should be performed based on the point and direction inversion.
//It returns true if the direction should be inverted and false otherwise.
function shouldPerformRaycast(point: SATVector, directionInverted: boolean): boolean {
    const invertDirection = point.x < 0;
    return directionInverted !== invertDirection;
}

//calculates the marker's next position based on raycast data and its current position.
//It updates the marker's position and returns the new coordinates along with a direction flag.
function determineNextPosition(
    markerPoly: MarkerPoly,
    raycastSets: RaycastSets
    ) {
    const { left, right } = raycastSets;
    const { closestVector } = left.hasMissed || left.raycasts.length <= 0 ? right : left;
    const { x, y } = markerPoly;

    markerPoly.setPosition(x + closestVector.x, y + closestVector.y);

    return {
        x: markerPoly.x,
        y: markerPoly.y,
        directionInverted: !left.hasMissed && left.raycasts.length > 0
    };
}

//Create a raycast on each corner point of the object
function calculateRaycastEndpoints(
    point: SATVector,
    normalizedDirection: Coordinates,
    markerPoly: MarkerPoly
    ): { start: Coordinates, end: Coordinates } {
    const invertDirection = point.x < 0;

    const corner = markerPoly.calcPoints[markerPoly.points.indexOf(point)];

    const start = { x: corner.x + markerPoly.x, y: corner.y + markerPoly.y };
    const end = {
        x: start.x + (invertDirection ? -1 : 1) * normalizedDirection.x * 800,
        y: start.y + (invertDirection ? -1 : 1) * normalizedDirection.y * 800,
    };

    return { start, end };
}

function performRaycast(start: Coordinates, end: Coordinates, closestWall: Wall): RaycastHit<Body> {
    return collisionSystem.raycast(start, end, (collision) => {
        //change isMarker in the future to current marker
        //so collisions can happen with other markers
        return !collision.isMarker && collision.wall_id !== closestWall.id
    });
}

function updateClosestHit(currentSet: RaycastSet, start: Coordinates, hit: RaycastHit<Body>): void {
    if (hit) {
        const distance = Math.sqrt(Math.pow(hit.point.x - start.x, 2) + Math.pow(hit.point.y - start.y, 2));
        if (distance < currentSet.closestDistance) {
            currentSet.closestDistance = distance;
            currentSet.closestHit = hit;
            currentSet.closestVector = { x: hit.point.x - start.x, y: hit.point.y - start.y };
        }
    } else {
        currentSet.hasMissed = true;
    }
}

function removeRaycastLinesFromTree(raycastLines: Line[]): void {
    raycastLines.forEach((line) => {
        collisionSystem.remove(line);
    });
}

function drawDebugHits(hits: RaycastHit<Body>[]): void {
    debugCollision.drawCallback = () => {
        debugCollision.context.strokeStyle = "#FF0000";

        hits.forEach(hit => {
            if(!hit){ return }
            const { point, body } = hit;
            debugCollision.context.beginPath();
            debugCollision.context.arc(point.x, point.y, 5, 0, 2 * Math.PI);
            debugCollision.context.stroke();
            // console.log("Hit at point:", point);
            // console.log("Body:", body.wall_id);
        });
    };
}

//Get the object rotation 'front' or 'right' by angle
export function objectRotation(
    rotation: 'right' | 'forward',
    angleRadians: number
    ): Coordinates {
    const vector = rotation === 'right' ? { x: 1, y: 0 } : { x: 0, y: 1 }

    return rotateVector(vector, angleRadians);
}

//Rotates a vector by a given angle
export function rotateVector(vector: Coordinates, angleRadians: number): Coordinates {
    // Calculate the new coordinates after rotation
    const newX = vector.x * Math.cos(angleRadians) - vector.y * Math.sin(angleRadians);
    const newY = vector.x * Math.sin(angleRadians) + vector.y * Math.cos(angleRadians);

    return {x: newX, y: newY}
}

export function findObjectBody(xid: number): MarkerPoly | undefined {
    return collisionSystem.all().find(body =>
        (body instanceof MarkerPoly) &&
        body.xid === xid
    ) as MarkerPoly;
}

export function findWallBody(wallId: number): WallLine | WallPoly | undefined {
    return collisionSystem.all().find(body =>
        (body instanceof WallLine || body instanceof WallPoly) &&
        body.wall_id === wallId
    ) as WallLine | WallPoly;
}

//remove wall from collision tree
export function removeWallCollisionTree(wallId: number): void {
    const wallBody = findWallBody(wallId);
    if(wallBody){
        collisionSystem.remove(wallBody);
        debugCollision.update()
    }
}

//remove object from collision tree
export function removeObjectCollisionTree(xid: number): void {
    const objectBody = findObjectBody(xid);
    if(objectBody){
        collisionSystem.remove(objectBody);
        debugCollision.update()
    }
}

//Create a new wall in the collision tree
//When you have a free wall create a polygon
//The room walls can just be lines
export function insertWallCollisionTree(wall: Wall, isFreeWall = false): void {
    //Create new wall for the collision tree
    const wallObject = isFreeWall ? createFreeWallPolygon(wall) : createRoomWallLine(wall);
    collisionSystem.insert(wallObject);
    debugCollision.update()
}

export function createFreeWallPolygon(wall: Wall): WallPoly {
    const roomStore = useRoomStore();
    const midPoint = wall.getCenter();
    const wallAngle = wall.getAngleRadians();
    const objectDimensions = {
        width: wall.getLength() / 2,
        height: roomStore.wallThickness / 2
    };
    const polygon = createObjectPolygon(midPoint.x, midPoint.y, objectDimensions);
    return new WallPoly(polygon, polygon.calcPoints, wall.id, {
        angle: wallAngle,
        isCentered: true,
    });
}

export function createRoomWallLine(wall: Wall): WallLine {
    const line = new Line(new SATVector(wall.from.x, wall.from.y), new SATVector(wall.to.x, wall.to.y));
    return new WallLine(line.start, line.end, wall.id, {
        isStatic: true,
        // isTrigger: true,
    });
}

export function insertMarkerCollisionTree(
    coordinates: Coordinates,
    xid: number,
    objectDimensions: { width: number, height: number, rotation: number },
    isTrigger = true,
) {
    const objectPolygon = createObjectPolygon(coordinates.x, coordinates.y, objectDimensions);
    collisionSystem.insert(
        new MarkerPoly(objectPolygon.pos, objectPolygon.calcPoints, xid, {
            angle: objectDimensions.rotation,
            isCentered: true,
            isTrigger: isTrigger,
        })
    );
}

// export function insertRoomCollisionTree(points: Coordinates[]){
//     collisionSystem.insert(new RoomPoly({}, points, {}))
// }


// const testMouseInRoom = ({ x, y }) => {
//     // create and add to tree
//     const circle = collisionSystem.createCircle({ x, y }, 6);
//     const roomBody = collisionSystem.all().find(body => body.isRoom)

//     // init as false
//     const collided = collisionSystem.checkCollision(circle, roomBody);

//     // remove from tree
//     collisionSystem.remove(circle)

//     return collided ? collisionSystem.response : null
//   }